import { MILLISECONDS_IN_DAY, getDaysBetweenDates } from "@smartrr/shared/utils/dateUtils";
import { DateTime } from "luxon";

export const getDisabledDateForCalendar = (billingTime: string, billingTimezone: string): Date => {
  const [hours = 0, minutes = 0, seconds = 0] = billingTime.split(":");
  const now = Date.now();
  const luxonNow = DateTime.fromMillis(now);
  const luxonNowShopTime = luxonNow.setZone(billingTimezone);
  const shopBillingTime = luxonNowShopTime.set({ hour: +hours, minute: +minutes, second: +seconds });
  const diffBetweenNowAndBillingTime = getDaysBetweenDates(shopBillingTime, luxonNow);

  let dayDifference = 0;

  // if billing time for today didn't occur yet, then we enable today for selection by pushing disabled local date to yesterday
  if (luxonNowShopTime < shopBillingTime) {
    dayDifference += 1;
  }

  // we including date difference only when local time is ahead of shop time
  if (diffBetweenNowAndBillingTime > 0) {
    dayDifference += diffBetweenNowAndBillingTime;
  }

  return new Date(now - dayDifference * MILLISECONDS_IN_DAY);
};
