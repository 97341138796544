// @globalize
export const extractErrorMessageFromHTMLPage = (error: string): string => {
  const message =
    error ||
    ""
      .match(/<pre>.*<\/pre>/g)![0]
      .replaceAll(/<\/?pre>/g, "")
      .replaceAll("&quot;", '"');

  try {
    const error = JSON.parse(message) as [{ field: string[]; message: string }];

    return error[0].message;
  } catch {
    return message;
  }
};
