import { Button, ButtonGroup, ChoiceList, FormLayout, Layout, LegacyCard, Link, Text } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { capitalize } from "lodash";
import React, { useMemo, useState } from "react";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
import { useShopSubscriptionGatewayEnabled } from "@smartrr/vendor-portal/src/utils/useShopSubscriptionGatewayEnabled";

import * as S from "./style";
import { SelectedPaymentMethodsEnum } from "../../../../AdminCreateSubscriptionRouteNew/libs";
import { PAYMENT_INFORMATION_SECTION_TITLE } from "../../constants";
import { UpdatePaymentMethodStateFunc } from "../../types";

export interface IPaymentInformation {
  selectedPaymentMethod: SelectedPaymentMethodsEnum;
  paymentMethodCanBeImportedFromShopify: boolean;
  selectedCustomerShopifyId: string;
  selectedShopifyPaymentMethodId: string[];
  selectedCustomerPaymentMethods: IPaymentMethod[];
  setSelectedPaymentMethod: (method: SelectedPaymentMethodsEnum) => void;
  setSelectedShopifyPaymentMethodId: (methodIds: string[]) => void;
  updateShopifyPaymentMethodsState: UpdatePaymentMethodStateFunc;
  children: (stripeIdInputEnabled: boolean) => JSX.Element;
}

export function PaymentInformation({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  paymentMethodCanBeImportedFromShopify,
  selectedCustomerPaymentMethods,
  updateShopifyPaymentMethodsState,
  selectedCustomerShopifyId,
  selectedShopifyPaymentMethodId,
  setSelectedShopifyPaymentMethodId,
  children,
}: IPaymentInformation): JSX.Element {
  const [isImporting, setisImporting] = useState(false);
  const { loading: subscriptionGatewayEnabledLoading, enabled: subscriptionGatewayEnabled } =
    useShopSubscriptionGatewayEnabled();

  const isShopify = selectedPaymentMethod === SelectedPaymentMethodsEnum.shopify;
  const isStripe = selectedPaymentMethod === SelectedPaymentMethodsEnum.stripe;
  const isPaypal = selectedPaymentMethod === SelectedPaymentMethodsEnum.paypal;

  const stripeIdInputEnabled = subscriptionGatewayEnabledLoading || subscriptionGatewayEnabled;
  const paymentMethodChoices = useMemo(
    () =>
      selectedCustomerPaymentMethods
        .filter(paymentMethod => paymentMethod.cardBrand !== "bogus" && !!paymentMethod.shopifyId)
        .filter(paymentMethod => paymentMethod.paymentMethodName !== "paypal")
        .map(paymentMethod => {
          const backupMask = paymentMethod.lastDigits ? `•••• •••• •••• ${paymentMethod.lastDigits}` : null;
          const cardBrand = (paymentMethod?.cardBrand || "")
            .split(" ")
            .map(word => capitalize(word))
            .join(" ");

          return {
            label: (
              <S.BankCard vertical gap={0.4}>
                <span>{cardBrand}</span>
                <span>{paymentMethod.maskedNumber || backupMask}</span>
                <span>
                  Expires {paymentMethod.cardExpirationMonth}/{paymentMethod.cardExpirationYear}
                </span>
              </S.BankCard>
            ),
            value: paymentMethod.shopifyId!,
          };
        }),
    [selectedCustomerPaymentMethods]
  );
  const paypalPaymentMethods = useMemo(
    () =>
      selectedCustomerPaymentMethods
        .filter(paymentMethod => paymentMethod.cardBrand !== "bogus" && !!paymentMethod.shopifyId)
        .filter(paymentMethod => paymentMethod.paymentMethodName === "paypal")
        .map((paymentMethod, i) => ({
          label: <span>Linked PayPal account {i + 1}</span>,
          value: paymentMethod.shopifyId!,
        })),
    [selectedCustomerPaymentMethods]
  );

  const onImportPaymentsMethods = async () => {
    if (isImporting) {
      return;
    }

    setisImporting(true);

    const paymentResponse = await typedFrontendVendorApi.postReq(
      "/customer-relationship/payment-methods/import",
      {
        reqBody: { customerShopifyId: selectedCustomerShopifyId },
      }
    );

    if (paymentResponse.type === "success") {
      updateShopifyPaymentMethodsState(paymentResponse.body);
    }

    setisImporting(false);
  };

  return (
    <Layout.AnnotatedSection
      title={PAYMENT_INFORMATION_SECTION_TITLE}
      description={
        <span>
          Enter the Stripe customer ID for this customer. Stripe must be set up as a secondary payment gateway as
          described{" "}
          <Link
            external
            url="https://shopify.dev/tutorials/migrate-existing-subscription-contracts-to-shopify#connect-stripe-as-a-secondary-payment-gateway"
          >
            here
          </Link>
          . This can be done by visiting this <Link url={`${adminConfigRoutePrefix}/migrations`}>page</Link> and
          clicking the &quot;Connect with Stripe&quot; button.
        </span>
      }
    >
      <LegacyCard>
        <LegacyCard.Section>
          <Box mb={1} style={{ width: "100%" }}>
            <ButtonGroup segmented fullWidth>
              <Button
                pressed={isShopify}
                onClick={() => setSelectedPaymentMethod(SelectedPaymentMethodsEnum.shopify)}
              >
                Shopify
              </Button>
              <Button
                pressed={isStripe}
                onClick={() => setSelectedPaymentMethod(SelectedPaymentMethodsEnum.stripe)}
              >
                Stripe
              </Button>
              <Button
                pressed={isPaypal}
                onClick={() => setSelectedPaymentMethod(SelectedPaymentMethodsEnum.paypal)}
              >
                PayPal
              </Button>
            </ButtonGroup>
          </Box>
          <FormLayout>
            <FormLayout.Group>
              <Box vertical gap={0.6}>
                {!!isStripe && (
                  <React.Fragment>
                    {children(stripeIdInputEnabled)}
                    {!stripeIdInputEnabled && (
                      <Text variant="bodyMd" as="span" color="critical">
                        {" "}
                        Smartrr has detected that this store does not have Stripe enabled as a secondary payment
                        gateway.
                      </Text>
                    )}
                  </React.Fragment>
                )}
                {!!isShopify && !paymentMethodChoices.length && (
                  <React.Fragment>
                    <Text variant="bodyMd" as="span" color="subdued">
                      No payment methods are available.
                    </Text>
                    {!!paymentMethodCanBeImportedFromShopify && (
                      <div>
                        <Button loading={isImporting} onClick={onImportPaymentsMethods}>
                          Import from Shopify
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {!!isShopify && !!paymentMethodChoices.length && (
                  <ChoiceList
                    title="Select payment method"
                    allowMultiple={false}
                    onChange={setSelectedShopifyPaymentMethodId}
                    selected={selectedShopifyPaymentMethodId}
                    choices={paymentMethodChoices}
                  />
                )}
                {!!isPaypal && !!paypalPaymentMethods.length && (
                  <ChoiceList
                    title="Select PayPal account"
                    allowMultiple={false}
                    onChange={setSelectedShopifyPaymentMethodId}
                    selected={selectedShopifyPaymentMethodId}
                    choices={paypalPaymentMethods}
                  />
                )}
                {!!isPaypal && !paypalPaymentMethods.length && (
                  <Text variant="bodyMd" as="span" color="subdued">
                    No linked PayPal accounts.
                  </Text>
                )}
              </Box>
            </FormLayout.Group>
          </FormLayout>
        </LegacyCard.Section>
      </LegacyCard>
    </Layout.AnnotatedSection>
  );
}
