import { EmptyState, Layout, LegacyCard, ResourceList, Select } from "@shopify/polaris";
import { Box, MediaMinWidths } from "@smartrr/shared/components/primitives";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import {
  getSellingPlanToProductAndVariantMapsFromSellingPlanGroups,
  getSellingPlansForVariants,
} from "@smartrr/shared/utils/sellingPlans";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import {
  usePurchasableVariants,
  useVendorPortalVariantToPurchasableMap,
} from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import * as S from "./styles";
import { PRODUCTS_TITLE } from "../../constants";
import { ResourseListItem, Variant } from "../../types";
import {
  getGroupedSellingPlan,
  getPriceAdjustmentForSelectedSellingPlan,
  getShopifySellingPlan,
} from "../../utils";
import { Product } from "../Product";

export interface IProducts {
  variantsToAdd: Variant;
  setVariantsToAdd: React.Dispatch<React.SetStateAction<Variant>>;
  selectedSellingPlanId: number | null;
  setSelectedSellingPlanId: (arg: number | null) => void;
  onModalOpen: () => void;
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
}

export const Products = (props: IProducts): JSX.Element => {
  const {
    variantsToAdd,
    selectedSellingPlanId,
    setSelectedSellingPlanId,
    setVariantsToAdd,
    onModalOpen,
    sellingPlanGroups,
  } = props;

  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const variants = usePurchasableVariants();
  const shopCurrency = useSmartrrVendorSelector(state => {
    return state.shopifyStoreData.shopifyData?.currency;
  });
  const isMobile = useMediaQuery({
    maxWidth: MediaMinWidths.Medium,
  });

  const activeVariants = useMemo(
    () => variants.filter(el => el.isActiveInShopify && !el.isDraftOrArchived),
    [variants]
  );
  const { sellingPlanGroupsByProductId, sellingPlanGroupsByVariantId } =
    getSellingPlanToProductAndVariantMapsFromSellingPlanGroups(sellingPlanGroups);

  const availableSellingPlans = getSellingPlansForVariants(
    activeVariants.filter(v => variantsToAdd.some(selectedVariant => selectedVariant.variantId === v.id)),
    sellingPlanGroupsByVariantId,
    sellingPlanGroupsByProductId,
    variantToPurchasableMap
  );
  const priceAdjustment = getPriceAdjustmentForSelectedSellingPlan(
    availableSellingPlans,
    sellingPlanGroups,
    selectedSellingPlanId
  );

  const shopifySellingPlan = getShopifySellingPlan(
    availableSellingPlans,
    sellingPlanGroups,
    selectedSellingPlanId
  );

  const deliveryMultiplier =
    (shopifySellingPlan?.billingPolicy.intervalCount || 1) /
    (shopifySellingPlan?.deliveryPolicy.intervalCount || 1);

  const hasSelectedVariants = !!variantsToAdd.length;
  const addProductsAction = useMemo(
    () => ({
      content: "Add products",
      onAction: onModalOpen,
    }),
    [onModalOpen]
  );
  const rightTopButtonForAddingProducts = useMemo(() => [addProductsAction], [onModalOpen]);

  const onChange = (value: string) => {
    const newValue = Number(value);
    const doesSellingPlanExist = availableSellingPlans.some(sp => sp.id === newValue);

    setSelectedSellingPlanId(doesSellingPlanExist ? newValue : null);
  };

  return (
    <Layout.AnnotatedSection
      title={PRODUCTS_TITLE}
      description="Add line items to this subscription. At least one must be specified. The added line items must also share at least one subscription program in common."
    >
      <LegacyCard>
        <LegacyCard.Section actions={hasSelectedVariants ? rightTopButtonForAddingProducts : undefined}>
          <Box vertical gap={1}>
            <Box alignItems="center" gap={1}>
              {!hasSelectedVariants && (
                <EmptyState
                  image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                  heading="No items added"
                  action={addProductsAction}
                >
                  <p>Add items by clicking the button below.</p>
                </EmptyState>
              )}
              {!!hasSelectedVariants && !!availableSellingPlans.length && (
                <S.SellingPlanWrapper isMobile={isMobile}>
                  <Select
                    label="Subscription Program"
                    options={getGroupedSellingPlan(availableSellingPlans)}
                    value={"" + selectedSellingPlanId}
                    onChange={onChange}
                  />
                </S.SellingPlanWrapper>
              )}
              {!!hasSelectedVariants && !availableSellingPlans.length && (
                <div>No selling plans available for this product combination.</div>
              )}
            </Box>
            <ResourceList
              items={variantsToAdd.map(({ variantId, quantity }) => {
                const variant = variants.find(v => v.id === variantId)!;
                const purchasable = variantToPurchasableMap[variant.id]!;

                return { variant, purchasable, quantity };
              })}
              renderItem={(itemProps: ResourseListItem) => (
                <Product
                  {...itemProps}
                  priceAdjustment={priceAdjustment}
                  shopCurrency={shopCurrency}
                  setVariantsToAdd={setVariantsToAdd}
                  deliveryMultiplier={deliveryMultiplier}
                />
              )}
            />
          </Box>
        </LegacyCard.Section>
      </LegacyCard>
    </Layout.AnnotatedSection>
  );
};
