import { Modal } from "@shopify/polaris";
import React from "react";

import { CONFIRMATION_MODAL_TITLE } from "../../constants";

export interface IConfirmationModalProps {
  open: boolean;
  setIsConfirmationOpen: (value: boolean) => void;
}

export function ConfirmationModal({ open, setIsConfirmationOpen }: IConfirmationModalProps): JSX.Element {
  return (
    <Modal
      open={open}
      onClose={() => setIsConfirmationOpen(false)}
      title={CONFIRMATION_MODAL_TITLE}
      primaryAction={{
        content: "Close",
        onAction: () => setIsConfirmationOpen(false),
      }}
    />
  );
}
