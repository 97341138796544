import { Modal, ResourceItem, ResourceList, Thumbnail } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";

import {
  usePurchasableVariants,
  useVendorPortalVariantToPurchasableMap,
} from "@vendor-app/app/_state/reducers/purchasables";

import { ADD_PRODUCT_MODAL_TITLE } from "../../constants";
import { ProductName } from "../ProductName";

export interface IAddProductModalProps {
  open: boolean;
  selected: string[];
  onAdd(variantIds: string[]): void;
  onClose(): void;
}

export function AddProductModal(props: IAddProductModalProps): JSX.Element {
  const { open, selected, onAdd, onClose } = props;
  const variants = usePurchasableVariants();
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const [variantIdsToAdd, setVariantIdsToAdd] = useState<string[]>(selected);

  const activeVariants = useMemo(
    () => variants.filter(el => el.isActiveInShopify && !el.isDraftOrArchived),
    [variants]
  );

  useEffect(() => {
    if (open) {
      setVariantIdsToAdd(selected);
    }
  }, [open]);

  return (
    <Modal
      title={ADD_PRODUCT_MODAL_TITLE}
      open={open}
      onClose={onClose}
      secondaryActions={[
        {
          content: "Cancel",
          onAction() {
            setVariantIdsToAdd(selected);
            onClose();
          },
        },
      ]}
      primaryAction={{
        content: "Add",
        onAction: () => onAdd(variantIdsToAdd),
      }}
    >
      <Modal.Section>
        <ResourceList
          items={activeVariants}
          selectedItems={variantIdsToAdd}
          onSelectionChange={items => setVariantIdsToAdd(items === "All" ? activeVariants.map(v => v.id) : items)}
          selectable
          renderItem={variant => {
            const purchasable = variantToPurchasableMap[variant.id];
            const imageUrl =
              variant.purchasableVariantSmallImages?.[0] || purchasable.purchasableSmallImages?.[0];
            const name = `${purchasable.purchasableName}${
              variant.purchasableVariantName ? ` - ${variant.purchasableVariantName}` : ""
            }`;
            return (
              <ResourceItem
                id={variant.id}
                media={imageUrl ? <Thumbnail source={imageUrl} alt={name} /> : <React.Fragment></React.Fragment>}
                verticalAlignment="center"
                onClick={() =>
                  setVariantIdsToAdd(
                    variantIdsToAdd.includes(variant.id)
                      ? variantIdsToAdd.filter(variantId => variantId !== variant.id)
                      : variantIdsToAdd.concat(variant.id)
                  )
                }
              >
                <ProductName
                  purchasableName={purchasable.purchasableName}
                  purchasableVariantName={variant.purchasableVariantName}
                />
              </ResourceItem>
            );
          }}
        />
      </Modal.Section>
    </Modal>
  );
}
