import { Button, FormLayout, LegacyCard, Select, TextField } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { SellingPlanInterval, SubscriptionBillingPolicyInput } from "@smartrr/shared/shopifyGraphQL/api";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CUSTOM_BILLING_POLICY_BUTTON_LABEL_ROOT } from "../../constants";

export interface ICustomBillingPolicy {
  changeBillingState: (billingPolicy?: SubscriptionBillingPolicyInput) => void;
}

const MIN_NUMBER_OF_INTERVALS = 1;

export function CustomBillingPolicy({ changeBillingState }: ICustomBillingPolicy): JSX.Element {
  const [billingPolicyActive, setBillingPolicyActive] = useState(false);
  const [billingPolicyIntervalCount, setBillingPolicyIntervalCount] = useState(MIN_NUMBER_OF_INTERVALS);
  const [billingPolicyInterval, setBillingPolicyInterval] = useState<SellingPlanInterval>(
    SellingPlanInterval.Day
  );

  const intervalTypeOptions = useMemo(
    () =>
      Object.values(SellingPlanInterval).map(value => ({
        label: `${capitalize(value)}s`,
        value,
      })),
    [SellingPlanInterval]
  );

  const onChangeActiveStatus = useCallback(() => {
    return setBillingPolicyActive(active => !active);
  }, []);

  const onChangeInterval = (value: string) => {
    setBillingPolicyInterval(value as SellingPlanInterval);
    changeBillingState({
      interval: value as SellingPlanInterval,
      intervalCount: billingPolicyIntervalCount,
    });
  };

  const onChangeIntervalCount = (value: string) => {
    const intervalCount = +value < MIN_NUMBER_OF_INTERVALS ? MIN_NUMBER_OF_INTERVALS : +value;

    setBillingPolicyIntervalCount(intervalCount);
    changeBillingState({
      interval: billingPolicyInterval,
      intervalCount,
    });
  };

  useEffect(() => {
    if (billingPolicyActive) {
      changeBillingState({
        interval: SellingPlanInterval.Day,
        intervalCount: MIN_NUMBER_OF_INTERVALS,
      });
    } else {
      changeBillingState(undefined);
      setBillingPolicyIntervalCount(MIN_NUMBER_OF_INTERVALS);
      setBillingPolicyInterval(SellingPlanInterval.Day);
    }
  }, [billingPolicyActive]);

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <Box mb={1.5}>
          <Button destructive={billingPolicyActive} onClick={onChangeActiveStatus}>
            {billingPolicyActive ? "Remove" : "Add"} {CUSTOM_BILLING_POLICY_BUTTON_LABEL_ROOT}
          </Button>
        </Box>
        <FormLayout>
          {!!billingPolicyActive && (
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                label="Number of intervals between billings"
                type="number"
                value={"" + billingPolicyIntervalCount}
                min={1}
                onChange={onChangeIntervalCount}
              />
              <Select
                label="Interval type"
                value={billingPolicyInterval}
                options={intervalTypeOptions}
                onChange={onChangeInterval}
              />
            </FormLayout.Group>
          )}
        </FormLayout>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
