import { useApolloClient } from "@apollo/client";
import { Button, DatePicker, FormLayout, Layout, LegacyCard, Page, Spinner } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import { ISmartrrCountry } from "@smartrr/shared/interfaces/Country";
import { CountryCode, SubscriptionBillingPolicyInput } from "@smartrr/shared/shopifyGraphQL/api";
import { queryShopifyCustomerPaymentMethods } from "@smartrr/shared/shopifyGraphQL/customer";
import { MILLISECONDS_IN_DAY, adjustToStoreBillingTime } from "@smartrr/shared/utils/dateUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import {
  createCustomerPurchaseState,
  putCustomerPurchaseStateSellingPlanAPI,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { usePurchasableVariants } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  AddProductModal,
  CREATE_SUBSCRIPTION_TITLE,
  ConfirmationModal,
  CustomBillingPolicy,
  CustomerAutocomplete,
  PaymentInformation,
  Products,
  UpdatePaymentMethodStateFunc,
  createValidationSchema,
  extractErrorMessageFromHTMLPage,
  initialFormValues,
} from "./libs";
import { getDisabledDateForCalendar } from "../AdminSubscriptionDetailsRoute/libs";
import { SelectedPaymentMethodsEnum } from "../AdminCreateSubscriptionRouteNew/libs";

export function AdminCreateSubscriptionRoute(): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const variants = usePurchasableVariants();
  const activeOrg = useActiveOrganizationSelector();

  const [isAddingVariants, setIsAddingVariantsTrue, setIsAddingVariantsFalse] = useBoolean(false);
  const [variantsToAdd, setVariantsToAdd] = useState<{ variantId: string; quantity: number }[]>([]);
  const [isLoadingSellingPlanGroups, setIsLoadingSellingPlanGroups] = useState(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isShippingAvailable, setIsShippingAvailable] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSubscriprionCreationInProgress, setIsSubscriprionCreationInProgress] = useState(false);
  const [sellingPlanGroups, setSellingPlanGroups] = useState<ISmartrrSellingPlanGroup[]>([]);
  const [countriesList, setCountriesList] = useState<ISmartrrCountry[]>([]);
  const [selectedSellingPlanId, setSelectedSellingPlanId] = useState<number | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethodsEnum>(
    SelectedPaymentMethodsEnum.shopify
  );
  const [billingPolicy, changeBillingPolicy] = useState<SubscriptionBillingPolicyInput | undefined>();
  const [keyForResettingSubscription, setKeyForResettingSubscription] = useState<number>(1);

  const [selectedCustomerPaymentMethods, setSelectedCustomerPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [selectedShopifyPaymentMethodId, setSelectedShopifyPaymentMethodId] = useState<string[]>([]);
  const [selectedCustomerShopifyId, setSelectedCustomerShopifyId] = useState("");
  const [selectedCustomerCustRelId, setSelectedCustomerCustRelId] = useState("");
  const [paymentMethodCanBeImportedFromShopify, setPaymentMethodCanBeImportedFromShopify] = useState(false);

  const client = useApolloClient();

  const disabledDate = getDisabledDateForCalendar(activeOrg!.billingTime, activeOrg!.billingTimezone);
  const firstAvailableDate = new Date(disabledDate.valueOf() + MILLISECONDS_IN_DAY);
  const [{ month, year }, setDate] = useState({
    month: firstAvailableDate.getMonth(),
    year: firstAvailableDate.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: firstAvailableDate,
    end: firstAvailableDate,
  });

  const { addToast } = useToast();

  const { useField, useValues, setFieldValue, validateForm } = useTypedForm({
    validationSchema: createValidationSchema(countriesList),
    initialValues: initialFormValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: NO_OP_CALLBACK,
  });

  const values = useValues();
  const selectedCountry = countriesList.find(country => country.countryCode === values.country);

  const { Input: StripeCustomerIdInput } = useField("stripeCustomerId");
  const { Input: FirstNameInput } = useField("firstName");
  const { Input: LastNameInput } = useField("lastName");
  const { Input: EmailInput } = useField("email");
  const { Input: Address1Input } = useField("address1");
  const { Input: Address2Input } = useField("address2");
  const { Input: CityInput } = useField("city");
  const { Dropdown: CountrySelect } = useField("country");
  const { Dropdown: ProvinceSelect } = useField("province");
  const { Input: PhoneInput } = useField("phone");
  const { Input: ZipInput } = useField("zip");

  const countriesListOptions = useMemo(
    () =>
      (countriesList || []).map(country => ({
        label: country.country,
        value: country.countryCode as CountryCode,
      })),
    [countriesList]
  );

  const provinceListOptions = useMemo(() => {
    if (selectedCountry?.provinces.length) {
      return selectedCountry.provinces.map(province => ({
        label: province.province,
        value: province.provinceCode,
      }));
    }
  }, [selectedCountry]);

  const isLoading = isLoadingSellingPlanGroups || isLoadingCountries;

  const fetchSellingPlanGroups = async () => {
    setIsLoadingSellingPlanGroups(true);
    const res = await typedFrontendVendorApi.getReq("/selling-plan-group");
    setIsLoadingSellingPlanGroups(false);

    if (res.type === "error") {
      addToast(`Error fetching subscription program groups`);
      return;
    }

    setSellingPlanGroups(res.body);
  };

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const fetchCountries = async () => {
    setIsLoadingCountries(true);
    const res = await typedFrontendVendorApi.getReq("/countries/data");
    setIsLoadingCountries(false);

    if (res.type === "error") {
      addToast("Error loading country data");
      return;
    }

    setCountriesList(res.body);
  };

  const onCreateSubscription = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      return;
    }

    if (!variantsToAdd.length) {
      addToast("Please add a product");
      return;
    }

    const {
      address1,
      address2,
      city,
      email,
      firstName,
      lastName,
      phone,
      zip,
      stripeCustomerId,
      country,
      province,
    } = values;
    if (selectedPaymentMethod === SelectedPaymentMethodsEnum.stripe && !stripeCustomerId) {
      addToast("Stripe customer ID is required");
      return;
    }

    if (selectedPaymentMethod === SelectedPaymentMethodsEnum.shopify && !selectedShopifyPaymentMethodId.length) {
      addToast("Please select a Shopify payment method");
      return;
    }

    if (!billingPolicy && !selectedSellingPlanId) {
      addToast("Please select subscription program or add a custom billing policy");
      return;
    }

    setIsSubscriprionCreationInProgress(true);

    const action = await dispatch(
      createCustomerPurchaseState({
        lines: variantsToAdd.map(({ quantity, variantId }) => {
          const variant = variants.find(v => v.id === variantId)!;

          return {
            quantity,
            variantId: variant.shopifyId!,
          };
        }),
        sellingPlanId: selectedSellingPlanId || undefined,
        startDate: adjustToStoreBillingTime(
          selectedDates.start,
          activeOrg!.billingTime,
          activeOrg!.billingTimezone
        ),
        stripeCustomerId,
        shopifyPaymentMethodId: selectedShopifyPaymentMethodId[0],
        shippingInfo: {
          address1,
          address2,
          city,
          email,
          firstName,
          lastName,
          phone,
          zip,
          countryCode: country as CountryCode,
          provinceCode: province,
        },
        billingPolicy,
        status: "ACTIVE",
      })
    );

    if (action.type === "ERROR_CREATING_CUSTOMER_PURCHASE_STATE") {
      const errorMessage = extractErrorMessageFromHTMLPage(action.payload.errorMessage);
      const toastText =
        errorMessage === "Bad Request" ? "Please make sure shipping information is correct" : errorMessage;

      addToast(toastText);
    } else if (selectedSellingPlanId) {
      addToast("Applying discount...");
      const discountResp = await putCustomerPurchaseStateSellingPlanAPI(
        selectedCustomerCustRelId,
        action.payload?.customerPurchaseState.id!,
        selectedSellingPlanId
      );

      if (discountResp.type === "success") {
        setIsConfirmationOpen(true);
        resetAllSubscriptionFields();
      } else {
        addToast(extractErrorMessageFromHTMLPage(discountResp.message));
      }
    } else {
      setIsConfirmationOpen(true);
      resetAllSubscriptionFields();
    }

    setIsSubscriprionCreationInProgress(false);
  };

  const resetAllSubscriptionFields = () => {
    setSelectedDates({
      start: firstAvailableDate,
      end: firstAvailableDate,
    });
    setSelectedCustomerPaymentMethods([]);
    setSelectedShopifyPaymentMethodId([]);
    setSelectedCustomerShopifyId("");
    setSelectedCustomerCustRelId("");
    setVariantsToAdd([]);
    setSelectedSellingPlanId(null);
    changeBillingPolicy(undefined);
    setIsShippingAvailable(false);
    setKeyForResettingSubscription(prevState => ++prevState);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateShopifyPaymentMethodsState: UpdatePaymentMethodStateFunc = paymentMethods => {
    const firstAvailablePaymentMethod: string[] = paymentMethods
      .filter(paymentMethod => paymentMethod.cardBrand !== "bogus" && !!paymentMethod.shopifyId)
      .splice(0, 1)
      .map(payment => payment.shopifyId!);

    setSelectedCustomerPaymentMethods(paymentMethods);
    setSelectedShopifyPaymentMethodId(firstAvailablePaymentMethod);
  };

  const loadCustomerPaymentMethods = async (
    customerRelationshipId: string,
    customerShopifyId: string,
    custRelId: string
  ) => {
    setPaymentMethodCanBeImportedFromShopify(false);
    setSelectedCustomerShopifyId("");
    setSelectedCustomerCustRelId("");

    const res = await typedFrontendVendorApi.getReq(
      "/customer-relationship/:customerRelationshipId/payment-methods",
      {
        params: {
          customerRelationshipId,
        },
      }
    );

    if (res.type === "success") {
      updateShopifyPaymentMethodsState(res.body as IPaymentMethod[]);
    } else {
      updateShopifyPaymentMethodsState([]);
    }

    if (res.type === "error" || (res.type === "success" && !(res.body as IPaymentMethod[]).length)) {
      const shopifyResponse = await queryShopifyCustomerPaymentMethods(client as any, customerShopifyId);

      if (shopifyResponse.type === "success" && shopifyResponse.body.data.customer) {
        setPaymentMethodCanBeImportedFromShopify(true);
      }
    }

    setSelectedCustomerShopifyId(customerShopifyId);
    setSelectedCustomerCustRelId(custRelId);
  };

  useEffect(() => {
    fetchSellingPlanGroups();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (values === initialFormValues) {
      return;
    }

    const { provinces, zipRequired } = selectedCountry || { provinces: [], zipRequired: false };

    if (provinces.length && !values.province) {
      setFieldValue("province", provinces[0].provinceCode);
    }
    if (!provinces.length) {
      setFieldValue("province", undefined);
    }
    if (!zipRequired && values.zip) {
      setFieldValue("zip", undefined);
    }
  }, [values, selectedCountry]);

  return (
    <Page title={CREATE_SUBSCRIPTION_TITLE}>
      <Layout>
        {isLoading ? (
          <Layout.Section>
            <Box mt={4} justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          </Layout.Section>
        ) : (
          <React.Fragment>
            <Layout.AnnotatedSection
              title="Scheduling"
              description="Select the first billing date for this subscription."
            >
              <LegacyCard>
                <LegacyCard.Section>
                  <FormLayout>
                    <FormLayout.Group>
                      <DatePicker
                        // disableDatesBefore disables date including passed
                        disableDatesBefore={disabledDate}
                        month={month}
                        year={year}
                        onChange={setSelectedDates}
                        onMonthChange={handleMonthChange}
                        selected={selectedDates}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </LegacyCard.Section>
              </LegacyCard>
              <CustomBillingPolicy
                changeBillingState={changeBillingPolicy}
                key={`billing-${keyForResettingSubscription}`}
              />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Customer information"
              description="Enter the email of an existing Shopify customer."
            >
              <CustomerAutocomplete
                key={`customer-${keyForResettingSubscription}`}
                loadCustomerPaymentMethods={loadCustomerPaymentMethods}
                setFieldValue={setFieldValue}
                setIsShippingAvailable={setIsShippingAvailable}
                updateShopifyPaymentMethodsState={updateShopifyPaymentMethodsState}
              />
            </Layout.AnnotatedSection>
            {!!isShippingAvailable && (
              <React.Fragment>
                <PaymentInformation
                  paymentMethodCanBeImportedFromShopify={paymentMethodCanBeImportedFromShopify}
                  selectedCustomerPaymentMethods={selectedCustomerPaymentMethods}
                  selectedCustomerShopifyId={selectedCustomerShopifyId}
                  selectedPaymentMethod={selectedPaymentMethod}
                  selectedShopifyPaymentMethodId={selectedShopifyPaymentMethodId}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  setSelectedShopifyPaymentMethodId={setSelectedShopifyPaymentMethodId}
                  updateShopifyPaymentMethodsState={updateShopifyPaymentMethodsState}
                >
                  {isEnabled => (
                    <StripeCustomerIdInput
                      usePolaris
                      placeholder="Stripe customer ID"
                      label=""
                      type="text"
                      disabled={!isEnabled}
                    />
                  )}
                </PaymentInformation>
                <Layout.AnnotatedSection
                  title="Shipping information"
                  description="Enter the shipping info for this subscription."
                >
                  <LegacyCard>
                    <LegacyCard.Section>
                      <FormLayout>
                        <FormLayout.Group>
                          <EmailInput usePolaris disabled label="" type="text" />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <FirstNameInput usePolaris label="" type="text" />
                          <LastNameInput usePolaris label="" type="text" />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <Address1Input usePolaris label="" type="text" />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <Address2Input usePolaris label="" type="text" />
                        </FormLayout.Group>

                        <FormLayout.Group>
                          <CityInput usePolaris label="" type="text" />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          {!!selectedCountry && !!selectedCountry.provinces.length && !!provinceListOptions && (
                            <ProvinceSelect usePolaris description="" options={provinceListOptions} label="" />
                          )}
                          {/* default to the US assuming the US is in the list, in the future we may filter to shop-specific countries */}
                          <CountrySelect usePolaris description="" options={countriesListOptions} label="" />
                        </FormLayout.Group>
                        {!!selectedCountry && !!selectedCountry.zipRequired && (
                          <FormLayout.Group>
                            {/* @ts-ignore */}
                            <ZipInput usePolaris label="" type="text" />
                          </FormLayout.Group>
                        )}
                        <FormLayout.Group>
                          <PhoneInput usePolaris label="" type="text" />
                        </FormLayout.Group>
                      </FormLayout>
                    </LegacyCard.Section>
                  </LegacyCard>
                </Layout.AnnotatedSection>
                <Products
                  onModalOpen={setIsAddingVariantsTrue}
                  selectedSellingPlanId={selectedSellingPlanId}
                  setSelectedSellingPlanId={setSelectedSellingPlanId}
                  setVariantsToAdd={setVariantsToAdd}
                  variantsToAdd={variantsToAdd}
                  sellingPlanGroups={sellingPlanGroups}
                />
              </React.Fragment>
            )}
            <Layout.Section>
              <Box justifyContent="flex-end">
                <Button
                  onClick={onCreateSubscription}
                  disabled={!isShippingAvailable}
                  loading={isSubscriprionCreationInProgress}
                  primary
                >
                  Create subscription
                </Button>
              </Box>
            </Layout.Section>
          </React.Fragment>
        )}
      </Layout>

      <AddProductModal
        open={isAddingVariants}
        onAdd={ids => {
          const updatedVariantsToAdd = ids.map(
            id =>
              variantsToAdd.find(({ variantId: existingId }) => existingId === id) || {
                variantId: id,
                quantity: 1,
              }
          );
          setVariantsToAdd(updatedVariantsToAdd);
          setIsAddingVariantsFalse();
        }}
        selected={variantsToAdd.map(v => v.variantId)}
        onClose={setIsAddingVariantsFalse}
      />
      <ConfirmationModal open={isConfirmationOpen} setIsConfirmationOpen={setIsConfirmationOpen} />
    </Page>
  );
}
