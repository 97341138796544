import { Icon, ResourceItem, TextField, Thumbnail } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives/Box";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { IPricingPolicyCycleAdjustment } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { IShopifyData } from "@smartrr/shared/entities/ShopifyData";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";
import { getAdjustedPriceForAdjustment } from "@smartrr/shared/utils/sharedTranslations/pricingPolicy";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";
import React from "react";

import { ResourseListItem, Variant } from "../../types";
import { getItemImageUrl, getItemName } from "../../utils";
import { ProductName } from "../ProductName";

export interface IProduct extends ResourseListItem {
  shopCurrency?: IShopifyData["currency"];
  priceAdjustment: IPricingPolicyCycleAdjustment;
  setVariantsToAdd: React.Dispatch<React.SetStateAction<Variant>>;
  deliveryMultiplier: number;
}

export const Product = ({
  variant,
  purchasable,
  quantity,
  shopCurrency,
  setVariantsToAdd,
  priceAdjustment,
  deliveryMultiplier,
}: IProduct) => {
  const name = getItemName(variant, purchasable);
  const imageUrl = getItemImageUrl(variant, purchasable);
  const price = variant.presentmentPrices.find(p => p.priceCurrency === shopCurrency)!.price * deliveryMultiplier;

  const onCurrencyChange = (value: string) => {
    setVariantsToAdd(variants =>
      updateMatchInArray(
        variants,
        {
          variantId: variant.id,
          quantity: +value,
        },
        ({ variantId }) => variantId
      )
    );
  };

  const onDeleteItem = () =>
    setVariantsToAdd(variants => variants.filter(({ variantId: id }) => id !== variant.id));

  return (
    <ResourceItem
      id={variant.id}
      persistActions
      media={imageUrl ? <Thumbnail source={imageUrl} alt={name} /> : <React.Fragment></React.Fragment>}
      verticalAlignment="center"
      onClick={NO_OP_CALLBACK}
    >
      <Box justifyContent="space-between" alignItems="center">
        <div style={{ width: "0", height: "0" }} data-testid="product" />
        <ProductName
          purchasableName={purchasable.purchasableName}
          purchasableVariantName={variant.purchasableVariantName}
        />
        <Box alignItems="center" gap={1}>
          {!!shopCurrency && (
            <TextField
              autoComplete="off"
              label=""
              labelHidden
              min={1}
              value={"" + quantity}
              onChange={onCurrencyChange}
              type="number"
              connectedLeft={
                <Box gap={0.6} mx={1} alignItems="center" style={{ height: "100%" }}>
                  <span>
                    {formatMoney(
                      getAdjustedPriceForAdjustment(price, shopCurrency as CurrencyCode, priceAdjustment),
                      shopCurrency
                    )}
                  </span>
                  <span>x</span>
                </Box>
              }
            />
          )}
          <div onClick={onDeleteItem}>
            <Icon source={DeleteMajor} color="critical" />
          </div>
        </Box>
      </Box>
    </ResourceItem>
  );
};
